<template>
  <div class="no-data-container" :class="{ '--included': isIncluded }">
    <div class="no-data-heading">{{ text }}</div>
    <div class="no-data-img">
      <img v-if="isComptalib()" src="~/assets/svg/NoDataComptalib.svg" >
      <SvgNoDataSvg v-else-if="isLight" />
      <SvgNoDataPageSvg v-else />
    </div>
  </div>
</template>

<script setup lang="ts">
withDefaults(
  defineProps<{
    text?: string;
    isLight?: boolean;
    isIncluded?: boolean;
  }>(),
  {
    text: "",
    isLight: false,
  },
);

const { isComptalib } = useBrandsComposable();
</script>

<style lang="scss" scoped>
.no-data-container {
  // avoid blocking the clicks on the filters that can be located below
  pointer-events: none;

  .no-data-heading {
    margin-top: 90px;
    text-align: center;
    color: #000;
    font-size: 26px;
    line-height: 46px;
  }

  .no-data-img {
    margin-top: 25px;
    text-align: center;
  }

  &.--included {
    background-color: $uds-white;
    padding: 90px 0;
    border: 1px solid $uds-neutral-500;
    border-radius: 0 0 $uds-radius-1 $uds-radius-1;

    .no-data-heading {
      margin-top: 0;
    }
  }
}
</style>
